html,
body {
    height: 100vh;
    width: 100%;
}

#site-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


#quote-box {
    max-width: 500px;
    border: white 2px solid;
    background-color: #2E2E2E;
    align-self: center;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.29) 8px 10px 5px 2px;
    
}

#text {
    font-size: 1.2rem;
    margin: 20px;
    color: #E9E9E6;
    
}
#author{
    font-size: 1rem;
    color: #E9E9E6;
    margin-bottom: 20px;
    font-style: italic;
}

#tweet-quote, #facebook-quote, #email-quote {
    font-size: 2rem;
    padding: 5px;
}
#new-quote {
    font-size: 1rem;
}
.fa-quote-left {
    font-size: 2rem;
    color: #E9E9E6;
}

#button-container {
display: flex;
    align-items: center
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    }}